.iconfire {
    width: 50px;
    position: relative;
    height: 50px;
    overflow: hidden;
    flex-shrink: 0;
}
.iconfireParent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 36px 0px 16px;
    font-family: 'Baloo Thambi';
}
.iconfireParent2 {
    font-weight: 500;
    font-size: 20px;
    font-family: 'Baloo Thambi';
}
.pick {
    align-self: stretch;
    position: relative;
    font-size: 15px;
    letter-spacing: -0.24px;
    line-height: 20px;
    color: #1c1c1c;
}
.point {
    background-color: #fff;
    font-size: 11px;
    color: #ff0069;
    border-radius: 4px;
    font-weight: 600;
    padding: 3px 6px;
}
.connectinstagrambutton3 {
    position: relative; /* 가상 요소 배치용 */
    align-self: stretch;
    border-radius: 8px;
    overflow: hidden; /* 가상 요소가 버튼을 넘어가지 않도록 */
    background: none; /* 직접 배경 제거 */
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 17px;
    cursor: pointer;
    color: white; /* 텍스트 색상 */
    z-index: 1; /* 텍스트가 배경 위로 오도록 */
}

.connectinstagrambutton3::before {
    content: '';
    position: absolute;
    width: 100dvw;
    height: 100dvw;
    background: linear-gradient(-45deg, #ffd600, #ff7a00, #ff0069, #d300c5, #7638fa);
    background-size: 300% 300%;
    animation: colorChange 1.5s linear infinite;
    z-index: -1; /* 배경을 버튼 뒤로 보냄 */
}
@keyframes colorChange {
    0% {
        background-position: 0% 50%;
        transform: rotate(0deg);
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
        transform: rotate(360deg);
    }
}
.instagramFrame{
    width: 100%;
    position: relative;
}

.text2 {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
    color: #fff;
    padding-left: 10px;
}
.hotple1Icon {
    width: 129px;
    position: relative;
    height: 26px;
    overflow: hidden;
    flex-shrink: 0;
}
.iconfire1 {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.buttondefault {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-size: 17px;
    cursor: pointer;
}
.header {
    width: 100%;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px 40px;
    box-sizing: border-box;
    gap: 20px;
    text-align: center;
    font-size: 40px;
    color: #ff0069;
    font-family: Pretendard;
}

