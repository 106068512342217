.image1Icon {
  	position: absolute;
  	top: 0px;
  	left: 0px;
  	border-radius: 8px;
  	width: 114px;
  	height: 152px;
  	object-fit: cover;
}
.iconinstagramColor {
  	width: 13px;
  	position: absolute;
	left: 6px;
	bottom: 6px;
  	height: 13px;
	z-index: 1;
}
.username {
  	flex: 1;
  	position: relative;
  	letter-spacing: 0.06px;
  	line-height: 13px;
	font-size: 10px;
}
.infoframe {
  	position: absolute;
  	top: 112px;
  	left: 0px;
  	border-radius: 0px 0px 8px 8px;
  	background: linear-gradient(180deg, rgba(0, 0, 0, 0), #000);
  	width: 114px;
  	height: 40px;
  	display: flex;
  	flex-direction: row;
  	align-items: flex-end;
  	justify-content: flex-end;
  	padding: 6px;
  	box-sizing: border-box;
  	gap: 4px;
}
.image1frame {
	width: 114px;
  	position: relative;
  	height: 152px;
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}
.image2frame {
  	width: 114px;
  	position: relative;
  	height: 152px;
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}
.infoframe2 {
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.6);
	width: 114px;
	height: 152px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 6px;
	box-sizing: border-box;
	gap: 4px;
}
.infoframe3 {
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid #ff0069;
	width: 114px;
	height: 152px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 6px;
	box-sizing: border-box;
	gap: 4px;
}
.imagenum {
  	align-self: stretch;
  	position: relative;
  	letter-spacing: -0.2px;
  	line-height: 28px;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	height: 35px;
  	flex-shrink: 0;
}
.p {
  	margin: 0;
}
.txt {
	line-break: anywhere;
}
.txt2 {
	color: #ff0069;
	line-break: anywhere;
}
.div {
  	align-self: stretch;
  	position: relative;
  	font-size: 13px;
  	letter-spacing: -0.08px;
  	line-height: 18px;
  	font-weight: 600;
  	display: flex;
  	align-items: center;
    justify-content: center;
  	height: 35px;
  	flex-shrink: 0;
}
.infos {
  	position: absolute;
  	left: 10px;
  	width: 94px;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
	height: 100%;
  	justify-content: center;
  	gap: 4px;
  	text-align: center;
  	font-size: 22px;
}
.imageframe {
  	width: 100%;
  	display: flex;
  	flex-direction: row;
  	align-items: flex-start;
  	justify-content:flex-start;
  	gap: 8px;
}
.hotplename {
  	position: relative;
  	letter-spacing: -0.3px;
  	line-height: 22px;
  	font-weight: 600;
}
.iconshare {
	filter: brightness(0) saturate(100%) invert(83%) sepia(100%) saturate(0%) hue-rotate(71deg) brightness(106%) contrast(103%);
	padding-bottom: 6px;
}
.iconshare2 {
	filter: brightness(0) saturate(100%) invert(13%) sepia(87%) saturate(6540%) hue-rotate(331deg) brightness(105%) contrast(105%);
	padding-bottom: 6px;
}
.iconfire {
  	width: 18px;
  	position: relative;
  	height: 18px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.ratingtext {
  	position: relative;
  	letter-spacing: -0.08px;
  	line-height: 18px;
  	font-weight: 600;
}
.ratingframe {
  	flex: 1;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	text-align: center;
  	font-size: 13px;
  	color: #ff0069;
}
.title {
  	align-self: stretch;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 8px;
  	font-size: 17px;
}
.is_pick {
  	position: relative;
  	letter-spacing: 0.06px;
  	line-height: 13px;
	color: #ff0069;
}
.not_pick {
	position: relative;
	letter-spacing: 0.06px;
	line-height: 13px;
}
.tags {
	align-self: stretch;
	height: 21px;
  	width: 298px;
  	overflow-x:scroll;  /* X축 스크롤 가능하도록 설정 */
	overflow-y: hidden;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 6px;
	text-align: center;
	font-size: 11px;
	color: #686868;
 	white-space: nowrap;  /* 태그들이 한 줄에 유지되도록 설정 */
}

.tags::-webkit-scrollbar {
  	display: none;
}

.tagplaceDetail,
.tagplaceDetail1,
.tagplaceDetail2 {
	border-radius: 4px;
	width: max-content; /* 내용에 따라 너비가 조정되도록 설정 */
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px;
	font-weight: 600;
	white-space: nowrap;  /* 텍스트가 두 줄로 넘어가지 않도록 설정 */
}

.tagplaceDetail {
	background-color: #f8effe;
	color: #421cb3;
}

.tagplaceDetail1 {
	background-color: #fffaea;
	color: #b74400;
}

.tagplaceDetail2 {
	background-color: #f2f2f2;
}
.caption {
  	align-self: stretch;
  	position: relative;
  	font-size: 13px;
  	letter-spacing: -0.08px;
	padding-right: 8px;
  	line-height: 18px;
  	font-weight: 600;
}
.label {
  	position: relative;
  	font-size: 9px;
  	font-weight: 600;
	border-radius: 4px;
	padding: 2px 4px;
	color: #ffffff;
	background-color: #421cb3;
}
.taggedBy {
	text-decoration: none;
	color: #421cb3;
	font-weight: 600;
}
.commentUser {
  	font-weight: 600;
	text-decoration: none;
	color: #ff0069;
}
.span {
  	color: #1c1c1c;
}
.comment {
  	flex: 1;
  	position: relative;
  	line-height: 16px;
  	color: #ff0069;
}
.commentframe {
  	align-self: stretch;
  	display: flex;
  	flex-direction: row;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 12px;
  	color: #421cb3;
}
.period {
  	flex: 1;
  	position: relative;
  	line-height: 16px;
  	color: #1c1c1c;
}
.detailframe {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
	text-align: left;
	font-size: 12px;
	color: #1c1c1c;
	padding-bottom: 16px;
	border-bottom: 0.5px solid #cdcdcd;
}
.detailleft {
  	flex: 1;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 8px;
}
.detailrightbutton {
	border-left: 0.5px solid #cdcdcd;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 8px 8px 8px 20px;
	gap: 4px;
	text-align: center;
	font-size: 11px;
  	font-weight: 600;
	color: #686868;
  	cursor: pointer;
}
.iconlikeActive {
  	width: 24px;
  	position: relative;
  	height: 24px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.iconlikeOutlined{
	width: 24px;
	position: relative;
	height: 24px;
	overflow: hidden;
	filter: brightness(0) saturate(100%) invert(40%) sepia(0%) saturate(11%) hue-rotate(146deg) brightness(98%) contrast(84%);
	flex-shrink: 0;
}
.iconcloseLine {
	position: relative;
	height: 100%;
	overflow: hidden;
	filter: brightness(0) saturate(100%) invert(0%) sepia(86%) saturate(45%) hue-rotate(104deg) brightness(77%) contrast(78%);
	cursor: pointer;
}
.idText{
	color: #ff0069;
	text-align: left;
	font-size: 13px;
	font-weight: 600;
}
.mediaText{
	color: #1c1c1c;
	padding-left: 4px;
	flex-grow: 1;
	text-align: left;
	font-size: 13px;
	font-weight: 300;
}
.iconcloseLineParent {
	width: 100%;
	position: relative;
	height: 28px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding: 4px;
	box-sizing: border-box;
}
.myHotpleItem {
  	position: relative;
  	box-sizing: border-box;
  	width: 100%;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: center;
  	padding: 4px 16px 0px 16px;
  	gap: 8px;
  	text-align: right;
  	font-size: 11px;
  	color: #fff;
  	font-family: Pretendard;
	cursor: pointer;
}
.nearbyHotpleItem {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px 16px 0px 16px;
	gap: 8px;
	text-align: right;
	font-size: 11px;
	color: #fff;
	font-family: Pretendard;
	cursor: pointer;
}
[data-status="true"] {
	background-color: #ffefea;
}
.editrequesttitle {
	position: relative;
	line-height: 16px;
	font-weight: 500;
}
.editrequestnumber {
	position: relative;
	letter-spacing: -0.3px;
	line-height: 20px;
	font-weight: 600;
}
.editrequstbutton {
	width: 32px;
	border-radius: 20px;
	background-color: #ff7a00;
	height: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 15px;
	color: #fff;
}
.editrequestframe {
	width: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	text-align: left;
	font-size: 12px;
	color: #b74400;
	font-family: Pretendard;
}

@media (max-width: 510px){
	.infos {
		left: calc((((100dvw - 48px) / 3) - 94px) / 2);
	}
	.image1Icon {
		width: calc((100dvw - 48px) / 3);
		height: calc(((100dvw - 48px) / 3) / 3 * 4);
	}
	.infoframe {
		top: calc((((100dvw - 48px) / 3) / 3 * 4) - 40px);
		width: calc((100dvw - 48px) / 3);
  	}
	.image1frame {
		width: calc((100dvw - 48px) / 3);
		height: calc(((100dvw - 48px) / 3) / 3 * 4);
	}
	.image2frame {
		width: calc((100dvw - 48px) / 3);
		height: calc(((100dvw - 48px) / 3) / 3 * 4);
	}
	.infoframe2 {
		width: calc((100dvw - 48px) / 3);
		height: calc(((100dvw - 48px) / 3) / 3 * 4);
	}
	.infoframe3 {
		width: calc((100dvw - 48px) / 3);
		height: calc(((100dvw - 48px) / 3) / 3 * 4);
	}
	.detailframe {
		width: calc(100dvw - 32px);
	}
	.detailleft{
		width: calc(100dvw - 150px);
	}
	.detailrightbutton{
		max-width: 30px;
	}
	.imageframe {
		justify-content: space-between;
		gap: auto;
  }
}