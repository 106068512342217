.idpw {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.2px;
  line-height: 28px;
}
.idpw_duplicated{
  align-self: stretch;
  position: relative;
  letter-spacing: -0.2px;
  line-height: 28px;
  color: #ff0069;
}
.id {
  width: 32px;
  position: relative;
  letter-spacing: -0.3px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.idinput {
  flex: 1;
  border-radius: 8px;
  border: transparent;
  background-color: #f2f2f2;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px 10px 12px;
  box-sizing: border-box;
  gap: 47px;
  text-align: left;
  color: #1c1c1c;
  font-size: 15px;
}
.idinput:focus{
  outline: 1px solid #686868;
}
.idinput::placeholder{
  color: #686868;
}
.iconmarkerLineFilled {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.div {
  position: relative;
  letter-spacing: -0.24px;
  line-height: 20px;
}
.leftContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.iconcloseFilled {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.div1 {
  width: 44px;
  height: 44px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.idParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
}
.li {
  margin-bottom: 0px;
}
.li_invalid {
  color: #686868;
}
.ul {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: 16px;
}
.text {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.buttondefault {
  width: 80px;
  border-radius: 8px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ff0069;
}
.pwParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
}
.frameParent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  text-align: right;
  font-size: 15px;
}
.idpwParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
.text1 {
  position: relative;
  letter-spacing: -0.3px;
  line-height: 22px;
  font-weight: 600;
}
.text1_invalid {
  color: #B3B3B3;
}
.buttondefault1 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #ff0069;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.buttondefault1_invalid {
  align-self: stretch;
  border-radius: 8px;
  background-color: #f2f2f2;
  border: 1px solid #B3B3B3;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}
.buttondefaultWrapper {
  align-self: stretch;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 17px;
  color: #ff0069;
}
.agree{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}
.agree0{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-bottom: 2px;
  align-items: center;
  cursor: pointer;
}
.agreeText{
  font-size: 15px;
  height: 24px;
  line-height: 23px;
  font-weight: 500;
  color: #ff0069;
}
.agreeTextDisabled{
  font-size: 15px;
  height: 24px;
  line-height: 23px;
  font-weight: 500;
}
.agreeText0{
  margin-left: 29px;
  height: 20px;
  line-height: 18px;
  font-weight: 500;
  color: #ff0069;
}
.agreeText1{
  height: 20px;
  flex-grow: 1;
  line-height: 18px;
}
.agreeText2{
  height: 20px;
  line-height: 18px;
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
}
.agreeIcon{
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate(100%) invert(13%) sepia(95%) saturate(7452%) hue-rotate(328deg) brightness(96%) contrast(113%);
}
.agreeIconDisabled{
  width: 24px;
  height: 24px;
}
.agreeFrame{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
  color: #686868;
  text-align: start;
  font-size: 12px;
}
.header {
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px 8px;
  gap: 24px;
}
.headerParent {
  width: 100%;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 32px 40px;
  box-sizing: border-box;
  text-align: center;
  font-size: 22px;
  color: #1c1c1c;
  font-family: Pretendard;
}
  
@media (max-width: 510px){  
  .headerParent {
    width: 100%; /* 자식 요소의 너비를 부모 요소의 가용 너비만큼 설정 */
    box-sizing: border-box; /* 패딩을 포함한 너비를 계산 */
    padding: 16px 0px;
    top: auto;
    left: auto;
  }

  .header {
    padding: 24px;
  }
}