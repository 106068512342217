.p {
    margin: 0;
    color: #1c1c1c;
}
.p_color {
    margin: 0;
    color: #ff0069;
}
.b {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.label {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.instagrambutton {
    border-radius: 100px;
    background-color: #ffefea;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-size: 15px;
    letter-spacing: -0.3px;
    color: #ff0069;
    font-weight: 600;
    text-decoration: none;
}
.headerframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.div {
    width: 36px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.mapframeIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    flex-shrink: 0;
}
.positionMarker{
    position: absolute;
    margin-bottom: 30px;
    z-index: 100;
}
.nametext {
    position: relative;
    letter-spacing: -0.24px;
    line-height: 20px;
    text-align: left;
}
.nameframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.textframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}
.searchheaderChild {
    width: 100%;
    position: relative;
    background-color: #f2f2f2;
    height: 3px;
    min-height: 3px;
}
.leftinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
}
.placename {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.placeaddress {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    color: #686868;
}
.searchheader {
    width: calc(100% - 112px);
    max-height: 300px;
    position: absolute;
    top: 172;
    border-radius: 8px;
    background-color: #fff;
    border: 0.5px solid #e6e6e6;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 17px;
    color: #1c1c1c;
    font-family: Pretendard;
    z-index: 200;
}
.searchresult {
    align-self: stretch;
    border-bottom: 0.5px solid #cdcdcd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    text-align: left;
    font-size: 15px;
    color: #353535;
    cursor: pointer;
}
.captionFrame{
    display: flex;
    width: 366px;
    flex-direction: column;
    gap: 4px;
}
.mediaCaption{
    font-size: 11px;
    height: 100%;
    overflow-y: auto;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #e6e6e6;
}
.mediaCaption1{
    font-size: 14px;
    font-weight: 600;
}
.category {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    color: #686868;
}
.bottomframe {
    align-self: stretch;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    font-size: 13px;
}
.bottomframe::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 두께 */
}

.bottomframe::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.bottomframe::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.bottomframe::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.iconsearch {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    filter: brightness(0) saturate(100%) invert(0%) sepia(2%) saturate(6054%) hue-rotate(29deg) brightness(93%) contrast(78%);
}
.searchinput {
    width: 100%;
    border: transparent;
    border-radius: 0;
    background-color: transparent;
    text-align: left;
    color: #1c1c1c;
    font-family: Pretendard;
    font-size: 15px;
    white-space: nowrap;
    letter-spacing: -0.2px;
    line-height: 21px;
    text-decoration: none;
}
.searchinput:focus{
    outline: none;
}
.searchinput::placeholder{
    color: #CDCDCD;
}
.searchframe {
    align-self: stretch;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px 10px 12px;
    box-sizing: border-box;
    gap: 8px;
    text-align: left;
    font-size: 16px;
}
.searchframeActive {
    align-self: stretch;
    border-radius: 8px 8px 0px 0px;
    background-color: #f2f2f2;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px 10px 12px;
    box-sizing: border-box;
    gap: 8px;
    text-align: left;
    font-size: 16px;
}
.contentsframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    text-align: right;
    font-size: 15px;
}
.mainframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px 0px;
    gap: 24px;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.wrongButton {
    flex: 1;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.rightButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
}
.buttonsframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 17px;
    color: #ff0069;
}
.cancelButton {
    border-radius: 8px;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 19px 16px;
    box-sizing: border-box;
    cursor: pointer;
}
.hereButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
}
.hereButtonDisabled {
    flex: 1;
    border-radius: 8px;
    background-color: #f2f2f2;
    border: 1px solid #686868;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #686868;
    cursor: not-allowed;
}
.buttonsframe2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 15px;
    color: #818181;
}
.reviewmodal3 {
    width: 478px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 56px;
    box-sizing: border-box;
    gap: 24px;
    text-align: center;
    font-size: 22px;
    color: #1c1c1c;
    font-family: Pretendard;
    max-height: calc(100dvh - 24px);
    overflow-y: auto;
}
.reviewmodal3::-webkit-scrollbar {
    display: none;
}

@media (max-width: 510px){
    .reviewmodal3 {
        width: 100%;
        padding: 32px 24px;
    }
    .searchheader {
        width: calc(100% - 48px);
    }
    .captionFrame{
        width: 100%;
    }
}