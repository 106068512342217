.div {
    width: 80px;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.hotpleaccountid {
    flex: 1;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
    color: #ff0069;
}
.instagramaccountid {
    flex: 1;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
    color: #ff0069;
    text-decoration: none;
	cursor: pointer;
}
.adminButtons{
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: row;
}
.buttonLayout{
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
}
.buttonLayout2{
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
}
.logoutbutton {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    color: #686868;
    text-align: center;
    cursor: pointer;
}
.hotpleaccountinfo {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.connectinstagrambutton1 {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 600;
    color: #7638fa;
    text-align: center;
    cursor: pointer;
}
.accountinfo {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: left;
}
.pick {
    margin: 0;
}
.pickContainer {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.24px;
    line-height: 20px;
}
.iconinstagramColor {
    width: 24px;
    position: relative;
    height: 24px;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-size: 14px;
    font-weight: 600;
}
.connectinstagrambutton2 {
    width: 100%;
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 17px;
    color: #ff0069;
    cursor: pointer;
}
.connectinstagramframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    font-size: 15px;
}
.pick1 {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    flex-shrink: 0;
}
.pick2 {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    flex-shrink: 0;
}
.myhotplenum {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.2px;
    line-height: 25px;
    font-weight: 600;
    color: #ff0069;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13px;
    flex-shrink: 0;
}
.myhotplenum2 {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.2px;
    line-height: 25px;
    font-weight: 600;
    color: #7638fa;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13px;
    flex-shrink: 0;
}
.myhotpleinfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}
.verticalDivider{
    width: 0.7px;
    height: 50px ;
    background-color: #cdcdcd;
}
.hotpleinfos {
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #cdcdcd;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 8px 10px;
    gap: 10px;
    font-size: 13px;
}
.userheader {
    width: 100%;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 32px;
    box-sizing: border-box;
    gap: 28px;
    text-align: center;
    font-size: 16px;
    color: #1c1c1c;
    font-family: Pretendard;
}

