.image {
    position: relative;
    border-radius: 8px;
    height: 360px;
    object-fit: cover;
}
.imageMobile {
    position: relative;
    border-radius: 8px;
    height: 240px;
    object-fit: cover;
}
.div {
    align-self: stretch;
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 35px;
    flex-shrink: 0;
}
.vertical{
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 4px;
}
.topFrame{
    height: 22px;
    padding: 0px 4px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-direction: row;
}
.iconcloseLine {
	position: relative;
	height: 20px;
    width: 20px;
	filter: brightness(0) saturate(100%) invert(0%) sepia(86%) saturate(45%) hue-rotate(104deg) brightness(77%) contrast(78%);
	cursor: pointer;
}
.plusLine {
    line-height: 20px;
    height: 20px;
    padding-bottom: 5px;
    margin-left: 1px;
    font-size: 30px;
    font-weight: 300;
    font-family: Pretendard;
    color: #353535;
	cursor: pointer;
}
.plusLineThumb {
    line-height: 20px;
    height: 20px;
    padding-bottom: 5px;
    margin-left: 1px;
    font-size: 30px;
    font-weight: 300;
    font-family: Pretendard;
    color: transparent;
}
.imageTypePreview{
    font-size: 14px;
    font-family: Pretendard;
    font-weight: 600;
    color: #ff0069;
}
.imageTypeContent{
    font-size: 14px;
    font-family: Pretendard;
    font-weight: 600;
    color: #353535;
}
.frameGroup {
    position: relative;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.nextButtonActive {
    padding: 12px 24px 16px 24px;
    margin-right: 12px;
    text-align: center;
    align-self: center;
    border-radius: 8px;
    border: 1.5px solid #ff0069;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #ff0069;
    font-weight: 600;
    cursor: pointer;
}
.imageScroll{
    overflow-x: auto;
    padding-bottom: 4px;
}
.imageScroll::-webkit-scrollbar {
    height: 6px; /* 스크롤바의 두께 */
}

.imageScroll::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.imageScroll::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.imageScroll::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.frameParent {
    width: 366px;
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 8px;
}
.mediaCaption{
    font-size: 12px;
    user-select:text;
}

@media (max-width: 510px){
    .frameParent{
        width: 100%;
    }
}