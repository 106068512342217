.span {
    color: #1c1c1c;
}
.mainheader {
    align-self: stretch;
    position: relative;
    line-height: 34px;
}
.hotple {
    margin: 0;
}
.subheader {
    align-self: stretch;
    position: relative;
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
    color: #1c1c1c;
}
.mainheaderParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 24px;
    gap: 24px;
}
.iconmyLocation {
    filter: brightness(0) saturate(100%) invert(20%) sepia(87%) saturate(3126%) hue-rotate(254deg) brightness(99%) contrast(98%);
    width: 22px;
    position: relative;
    height: 22px;
    overflow: hidden;
    flex-shrink: 0;
}
.div {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.permission {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 8px;
    justify-content: center;
    gap: 2px;
}
.parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    text-align: center;
    font-size: 13px;
    color: #686868;
}
.permissionParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
}
.pick {
    width:fit-content;
    align-self: center;
    padding-right: 8px;
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
    display: inline-block;
    height: 18px;
    flex-shrink: 0;
}
.frameParent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 17px;
    color: #7638fa;
}
.hedaer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.cancleButton {
    border-radius: 8px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 19px 16px;
    box-sizing: border-box;
}
.iconsetting {
    width: 24px;
    position: relative;
    height: 24px;
}
.deleteButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 19px 0px;
    box-sizing: border-box;
    gap: 6px;
    font-size: 17px;
    color: #fff;
}
.buttons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 15px;
    color: #818181;
}
.contents {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
}
.container {
    width: 100%;
    background: #fff;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 24px;
    box-sizing: border-box;
    text-align: center;
    font-size: 28px;
    color: #ff0069;
    font-family: Pretendard;
}

