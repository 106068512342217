.b {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.instagrambutton {
    border-radius: 100px;
    background-color: #ffefea;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 20px;
    color: #ff0069;
    text-decoration: none;
}
.headerframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.captionFrame{
    display: flex;
    width: 366px;
    flex-direction: column;
    gap: 4px;
}
.mediaCaption{
    font-size: 11px;
    height: 100%;
    overflow-y: auto;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #e6e6e6;
}
.mediaCaption1{
    font-size: 14px;
    font-weight: 600;
}
.div {
    width: 36px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.iconpen {
    width: 18px;
    position: relative;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
}
.captioninput {
    width: 100%;
    border: transparent;
    border-radius: 0;
    background-color: transparent;
    text-align: left;
    color: #1c1c1c;
    font-family: Pretendard;
    font-size: 13px;
    white-space: nowrap;
    letter-spacing: -0.08px;
    line-height: 18px;
}
.captioninput:focus{
    outline: none;
}
.captioninput::placeholder{
    color: #CDCDCD;
}
.nameinput {
    flex: 1;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 44px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 10px 6px 12px;
    box-sizing: border-box;
    gap: 2px;
    text-align: left;
    font-size: 13px;
}
.captionframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.div1 {
    width: 36px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 30px;
    flex-shrink: 0;
}
.div2 {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
    text-decoration: none;
}
.tag1 {
    border-radius: 8px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 10px 6px 12px;
    gap: 2px;
}
.tagsinputframe {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    gap: 6px;
    text-align: center;
    font-size: 13px;
}
.tagsframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}
.iconinfo {
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}
.span {
    font-weight: 500;
    color: #ff6686;
}
.div5 {
    position: relative;
    line-height: 16px;
}
.text {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}
.periodinput1 {
    border-radius: 8px;
    width: 122px;
    background-color: #f2f2f2;
    border: transparent;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 6px 8px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: Pretendard;
}
.periodinput1:focus{
    outline: none;
}
.periodinput1::placeholder{
    color: #CDCDCD;
}
.div8 {
    position: relative;
    font-size: 15px;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    color: #686868;
}
.periodinputframe {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-align: center;
    font-size: 13px;
}
.periodframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: right;
    font-size: 15px;
    color: #1c1c1c;
}
.bottomframe {
    align-self: stretch;
    border-top: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px 0px;
    gap: 8px;
    text-align: left;
    font-size: 12px;
    color: #686868;
}
.contentsframe {
    align-self: stretch;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    text-align: right;
    font-size: 15px;
}
.text1 {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.nextButtonActive {
    width: 100%;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
}
.nextButton {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    border: 1px solid #686868;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #686868;
    cursor: not-allowed;
}
.loginframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px 0px;
    gap: 36px;
}
.reviewmodal2 {
    width: 478px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 56px;
    box-sizing: border-box;
    text-align: center;
    font-size: 22px;
    color: #1c1c1c;
    font-family: Pretendard;
    max-height: calc(100dvh - 24px);
    overflow-y: auto;
}
.reviewmodal2::-webkit-scrollbar {
    display: none;
}
.tag1Input {
    border: transparent;
    border-radius: 0;
    background-color: transparent;
    text-align: left;
    color: #1c1c1c;
    font-family: Pretendard;
    font-size: 13px;
    white-space: nowrap;
}
.tag1Input:focus{
    outline: none;
}
.tag1Input::placeholder{
    color: #CDCDCD;
}
.noPeriodButton {
    position: relative;
    border-radius: 100px;
    background-color: #ffefea;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    text-align: center;
    font-size: 15px;
    color: #ff0069;
    font-family: Pretendard;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    margin: 0px 32px;
}
.monthButton {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    color: #1c1c1c;
    padding: 4px 32px;
    cursor: pointer;
}
.monthText {
    font-family: Pretendard;
    font-size: 15px;
}
.editbuttonsframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 15px;
    color: #818181;
}
.cancelButton {
    border-radius: 8px;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 19px 16px;
    box-sizing: border-box;
    cursor: pointer;
}
.cancellabel {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.editButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
}
.editButtonDisabled {
    flex: 1;
    border-radius: 8px;
    background-color: #f2f2f2;
    border: 1px solid #686868;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #686868;
    cursor: not-allowed;
}
.edittext {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}


@media (max-width: 510px){
    .reviewmodal2 {
        width: 100%;
        padding: 32px 24px;
    }
    .captionFrame{
        width: 100%;
    }
}