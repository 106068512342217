.ai {
    margin: 0;
}
.aiContainer {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.title {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.label {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.instagrambutton {
    border-radius: 100px;
    background-color: #ffefea;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-size: 15px;
    color: #ff0069;
    text-decoration: none;
}
.captionFrame{
    display: flex;
    width: 366px;
    flex-direction: column;
    gap: 4px;
}
.mediaCaption{
    font-size: 11px;
    height: 100%;
    overflow-y: auto;
    padding: 8px;
    border-radius: 8px;
    background-color: #e6e6e6;
}
.mediaCaption1{
    font-size: 14px;
    font-weight: 600;
}
.instagrambutton1 {
    border-radius: 100px;
    background-color: #ffefea;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 20px;
    color: #ff0069;
    text-decoration: none;
    cursor: pointer;
}
.instagrambuttonDisabled {
    border-radius: 100px;
    background-color: #f2f2f2;
    border: 1px solid #686868;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 20px;
    color: #686868;
    cursor: not-allowed;
    text-decoration: none;
}
.headerframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.div {
    width: 42px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.iconpen {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}
.nameinput {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px 10px 12px;
    box-sizing: border-box;
    gap: 4px;
    text-align: left;
}
.nameframe {
    align-self: stretch;
    width: 100%;
    display: flex;
    gap: 12px;
}
.iconrestaurant {
    width: 20px;
    position: relative;
    height: 20px;
}
.label1 {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
}
.restarantbutton {
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    cursor: pointer;
}
.culturalbutton {
    border-radius: 100px;
    background-color: #ffefea;
    border: 0.5px solid #ff0069;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    color: #ff0069;
    cursor: pointer;
}
.anotherbutton {
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    cursor: pointer;
}
.buttonsframe {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 8px;
    text-align: center;
    font-size: 16px;
}
.categoryframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}
.div2 {
    width: 62px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.subcategory1input {
    position: relative;
    letter-spacing: -0.24px;
    line-height: 20px;
}
.subcategory1 {
    border-radius: 8px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 4px 6px 8px;
}
.div3 {
    position: relative;
    letter-spacing: -0.24px;
    line-height: 20px;
    color: #686868;
    text-align: left;
}
.subcateries {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    text-align: center;
}
.subcategoryframe {
    max-width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.contentsframe {
    width: 366px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    text-align: right;
    font-size: 15px;
}
.mainframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px 0px;
    gap: 36px;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.nextButtonActive {
    width: 100%;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
}
.nextButton {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    border: 1px solid #686868;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #686868;
    cursor: not-allowed;
}
.reviewmodal1 {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 56px;
    box-sizing: border-box;
    gap: 36px;
    text-align: center;
    font-size: 22px;
    color: #1c1c1c;
    font-family: Pretendard;
    max-height: calc(100dvh - 24px);
    overflow-y: auto;
}
.reviewmodal1::-webkit-scrollbar {
    display: none;
}

.nameInput1 {
    width: 100%;
    border: transparent;
    background-color: transparent;
    padding-right: 8px;
    text-align: left;
    color: #1c1c1c;
    font-family: Pretendard;
    font-size: 15px;
}
.nameInput1:focus{
    outline: none;
}
.nameInput1::placeholder{
    color: #CDCDCD;
}
.subCategoryInput1 {
    border: transparent;
    border-radius: 0;
    background-color: transparent;
    text-align: left;
    color: #1c1c1c;
    font-family: Pretendard;
    font-size: 15px;
    white-space: nowrap;
    max-width: 80px;
}
.subCategoryInput1:focus{
    outline: none;
}
.subCategoryInput1::placeholder{
    color: #CDCDCD;
}
.editbuttonsframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 15px;
    color: #818181;
}
.cancelButton {
    border-radius: 8px;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 19px 16px;
    box-sizing: border-box;
    cursor: pointer;
}
.cancellabel {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.editButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
}
.editButtonDisabled {
    flex: 1;
    border-radius: 8px;
    background-color: #f2f2f2;
    border: 1px solid #686868;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #686868;
    cursor: not-allowed;
}
.edittext {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}

@media (max-width: 510px){
    .reviewmodal1 {
        padding: 32px 24px;
    }

    .contentsframe{
        width: 100%;
    }

    .captionFrame{
        width: 100%;
    }
}