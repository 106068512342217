.span {
    color: #1c1c1c;
}
.mainheaderTxt {
    width: 100%;
}
.mainheader {
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 8px;
    align-self: center;
    line-height: 34px;
}
.hotple {
    margin: 0;
}
.mainheader1 {
    align-self: stretch;
    position: relative;
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 21px;
    color: #1c1c1c;
}
.div1 {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.numbering {
    width: 20px;
    border-radius: 10px;
    background-color: #ff0069;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.div2 {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.p {
    position: relative;
    letter-spacing: 0.06px;
    line-height: 13px;
    font-weight: 600;
}
.pFinish {
    position: relative;
    letter-spacing: 0.06px;
    text-decoration: line-through;
    line-height: 13px;
    font-weight: 600;
}
.tagpoint {
    border-radius: 4px;
    background-color: #ffefea;
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 6px;
    box-sizing: border-box;
    font-size: 11px;
    color: #ff0069;
}
.group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.div3 {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    color: #686868;
    text-align: left;
}
.text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    font-size: 17px;
    color: #1c1c1c;
}
.buttondefault {
    width: 75px;
    border-radius: 8px;
    background-color: #ffefea;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    box-sizing: border-box;
    color: #ff0069;
    cursor: pointer;
    outline: solid #ff0069 0px; /* 초기 outline 설정 */
    animation: outlinePulse 1s ease infinite; /* 애니메이션 추가 */
}
@keyframes outlinePulse {
    0% {
        outline-width: 0px;
    }
    50% {
        outline-width: 3px;
    }
    100% {
        outline-width: 0px;
    }
}
.div {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}
.numbering1 {
    width: 20px;
    border-radius: 10px;
    background-color: #9a9a9a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tagpoint1 {
    border-radius: 4px;
    background-color: #f2f2f2;
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 6px;
    box-sizing: border-box;
    font-size: 11px;
    color: #9a9a9a;
}
.text2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    font-size: 17px;
    color: #1c1c1c;
}
.text3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 400;
    font-size: 25px;
    color: #ff0069;
}
.rotate {
    transform: rotate(360deg);
    transition: transform 1s ease-in-out;
}
.text1 {
    position: relative;
    font-size: 17px;
    letter-spacing: -0.3px;
    padding-left: 10px;
    line-height: 22px;
    font-weight: 600;
}
.positiveButton {
    flex:1;
    position: relative; /* 가상 요소 배치용 */
    align-self: stretch;
    border-radius: 8px;
    overflow: hidden; /* 가상 요소가 버튼을 넘어가지 않도록 */
    background: none; /* 직접 배경 제거 */
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 17px;
    cursor: pointer;
    color: white; /* 텍스트 색상 */
    z-index: 1; /* 텍스트가 배경 위로 오도록 */
}

.positiveButton::before {
    content: '';
    position: absolute;
    width: 100dvw;
    height: 100dvw;
    background: linear-gradient(-45deg, #ffd600, #ff7a00, #ff0069, #d300c5, #7638fa);
    background-size: 300% 300%;
    animation: colorChange 1.5s linear infinite;
    z-index: -1; /* 배경을 버튼 뒤로 보냄 */
}
@keyframes colorChange {
    0% {
        background-position: 0% 50%;
        transform: rotate(0deg);
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
        transform: rotate(360deg);
    }
}

.positiveButton2 {
    width: 75px;
    border-radius: 8px;
    height: 50px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
}
.positiveButtonDisabled {
    flex: 1;
    border-radius: 8px;
    background-color: #f2f2f2;
    border: 1px solid #686868;
    height: 50px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #686868;
    cursor:not-allowed;
}
.point {
    background-color: #fff;
    font-size: 11px;
    color: #ff0069;
    border-radius: 4px;
    font-weight: 600;
    padding: 3px 6px;
}
.pointDisabled {
    background-color: #fff;
    font-size: 11px;
    color: #9a9a9a;
    border-radius: 4px;
    font-weight: 600;
    padding: 3px 6px;
}
.buttons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    justify-content: flex-start;
}
.hotple2Icon {
    width: 110px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}
.hotple3Icon {
    width: 60px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}
.buttondefault1 {
    width: 75px;
    border-radius: 8px;
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    box-sizing: border-box;
    color: #9a9a9a;
}
.parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    font-size: 15px;
    color: #fff;
}
.hedaer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
}
.container {
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 28px 12px 8px 12px;
    box-sizing: border-box;
}
.logoutmodal {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px 24px;
    box-sizing: border-box;
}
.div14 {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
}
.wrapper {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 13px;
    color: #818181;
    cursor: pointer;
}
.logoutmodalParent {
    width: 100%;
    position: relative;
    height: fit-content;
    text-align: center;
    font-size: 27px;
    color: #ff0069;
    font-family: Pretendard;
}
.modalcontainer {
    position: absolute;
    width: 478px;
    height: 542px;
    transform: translateY(50px); /* 초기 위치 (아래로 이동) */
    opacity: 0; /* 초기 투명도 */
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}
.modalbackground {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #1c1c1c;
    font-family: Pretendard;
    z-index: 1000;
    opacity: 0; /* 초기 상태 */
    transition: opacity 200ms ease-in-out; /* 200ms 동안 변화 */
    pointer-events: none; /* 비활성화 */
}
.modalbackground.visible {
    opacity: 1; /* 애니메이션 종료 후 상태 */
    pointer-events: auto; /* 클릭 가능 */
}
.modalcontainer.visible {
    transform: translateY(0); /* 원래 위치로 이동 */
    opacity: 1; /* 불투명 */
}

@media (max-width: 510px){
    .modalcontainer {
        width: 100%; /* 부모 요소가 페이지 전체를 차지한다고 가정 */
        padding: 0 12px; /* 좌우 패딩을 각각 16px 설정 */
        box-sizing: border-box; /* 패딩을 포함한 너비를 계산 */
    }
}