.b {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.headerframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.nametext {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.nametextDisabled{
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
    color: #818181;
}
.titleframe {
    align-self: stretch;
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 17px;
    color: #ff0069;
}
.deleteText{
    font-size: 13px;
    color: #818181;
    font-weight: 600;
    cursor: pointer;
}
.div {
    width: 42px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.iconpen {
    width: 18px;
    position: relative;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
}
.nameInput1 {
    width: 100%;
    border: transparent;
    background-color: transparent;
    padding-right: 8px;
    text-align: left;
    color: #1c1c1c;
    font-family: Pretendard;
    font-size: 15px;
}
.nameInput1:focus{
    outline: none;
}
.nameInput1::placeholder{
    color: #CDCDCD;
}
.nameinput {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px 10px 12px;
    box-sizing: border-box;
    gap: 4px;
    text-align: left;
}
.bannerInput0 {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px 10px 12px;
    box-sizing: border-box;
    gap: 4px;
    text-align: left;
    cursor: pointer;
}
.iconcloseLine{
    align-self: center;
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.bannerInput {
    overflow: hidden;
    text-overflow: ellipsis;
}
.nameframe {
    align-self: stretch;
    width: 100%;
    display: flex;
    gap: 6px;
}
.captioninput {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
}
.nameinput2 {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 10px 6px 12px;
    box-sizing: border-box;
    gap: 8px;
    text-align: left;
    font-size: 13px;
}
.div3 {
    width: 36px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 30px;
    flex-shrink: 0;
}
.div8 {
    position: relative;
    font-size: 15px;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    color: #686868;
}
.monthButton {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    color: #1c1c1c;
    padding: 4px 32px;
    cursor: pointer;
}
.monthText {
    font-family: Pretendard;
    font-size: 15px;
}
.periodinput1 {
    border-radius: 8px;
    width: 122px;
    background-color: #f2f2f2;
    border: transparent;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 6px 8px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: Pretendard;
}
.periodinput1:focus{
    outline: none;
}
.periodinput1::placeholder{
    color: #CDCDCD;
}
.div5 {
    position: relative;
    font-size: 15px;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    color: #686868;
}
.periodinputframe {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-align: center;
    font-size: 13px;
}
.div1 {
    width: 36px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 30px;
    flex-shrink: 0;
}
.periodframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: right;
    font-size: 15px;
    color: #1c1c1c;
}
.imageframeIcon {
    align-self: stretch;
    position: relative;
    border-radius: 8px;
    max-width: 100%;
    overflow: hidden;
    height: 80px;
    flex-shrink: 0;
    object-fit: cover;
    background-color: #1c1c1c;
}
.noticeframe {
    align-self: stretch;
    border-bottom: 0.5px solid #cdcdcd;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 0px;
    gap: 12px;
}
.innercontentframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: right;
    font-size: 15px;
}
.contentframe {
    width: 366px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 56px;
    gap: 24px;
    max-height: calc(100dvh - 200px);
    min-height: 60px;
    overflow-y: scroll;
    margin-bottom: 47px;
}
.contentframe::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 두께 */
}
.contentframe::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}
.contentframe::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}
.contentframe::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.backButton {
    flex: 1;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.addButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}
.addButtonDisabled {
    flex: 1;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #686868;
    cursor:not-allowed;
}
.editButton{
    width: 100%;
    border-radius: 8px;
    background-color: #ff0069;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}
.editButtonDisabled{
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #686868;
    cursor:not-allowed;
}
.editnoticemodal {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 24px;
    text-align: center;
    font-size: 22px;
    color: #1c1c1c;
    font-family: Pretendard;
}
.buttonFrame {
    position: absolute;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding: 15px 50px;
    box-sizing: border-box;
    font-size: 17px;
    color: #ff0069;
}
@media (max-width: 510px){
    .contentframe {
        width: calc(100% - 32px);
        padding: 32px 16px;
    }
}