.div {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
}
.div4 {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    width: 34px;
    text-align: center;
    font-weight: 600;
}
.iconfire {
    width: 21px;
    position: relative;
    height: 21px;
    overflow: hidden;
    flex-shrink: 0;
}
.iconfireParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    color: #ff0069;
}
.parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
}
.parentRail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 16px;
}
.iconinstagram {
    width: 21px;
    position: relative;
    height: 21px;
}
.div1 {
    flex: 1;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
}
.iconinstagramParent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    color: #ff0069;
}
.frameGroup {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
}
.div2 {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    color: #686868;
    text-align: center;
    cursor: pointer;
}
.rail{
    background-color: #cdcdcd;
    border-radius: 10px;
    flex-grow: 1; /* 남은 공간을 채움 */
    height: 20px;
    position: relative; /* train을 올바르게 배치하기 위해 relative 설정 */
    overflow: hidden; /* train이 rail을 넘어가는 부분을 숨김 */
}
.train{
    background-color: #ff0069;
    border-radius: 10px;
    width: 80px; /* 고정된 너비 */
    height: 20px;
    top: 0;
    position: absolute; /* 부모 rail 안에서 고정 위치 */
    left: 0; /* rail의 왼쪽에서 시작 */
    z-index: 10;
}
.infoFrame{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.level {
    background-color: #ff0069;
    font-size: 12px;
    color: #fff;
    border-radius: 6px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    height: 20px;
    width: 34px;
}
.xp{
    position: absolute;
    width: 100%;
    text-align: center;
    font-weight: 600;
    line-height: 20px;
    font-size: 12px;
    color: #fff;
    background-color: transparent;
    z-index: 100;
}
.frameParent {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 15px;
    color: #1c1c1c;
    font-family: Pretendard;
}
.frameParent2 {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 15px;
    gap: 16px;
    color: #1c1c1c;
    font-family: Pretendard;
    cursor: pointer;
}
.hotpleaccountid {
    flex: 1;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
    color: #ff0069;
}
.instagramaccountid {
    flex: 1;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
    color: #ff0069;
    text-decoration: none;
	cursor: pointer;
}
.logoutbutton {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    color: #686868;
    text-align: center;
    cursor: pointer;
}
.hotpleaccountinfo {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.connectinstagrambutton1 {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 600;
    color: #7638fa;
    text-align: center;
    cursor: pointer;
}
.accountinfo {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: left;
}
.pick {
    margin: 0;
}
.pickContainer {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.24px;
    line-height: 20px;
}
.iconinstagramColor {
    width: 24px;
    position: relative;
    height: 24px;
}
.iconfireParent2 {
    font-weight: 500;
    font-size: 20px;
    font-family: 'Baloo Thambi';
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.text2 {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
    color: #fff;
    padding-left: 10px;
}
.connectinstagrambutton2 {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 17px;
    color: #ff0069;
    cursor: pointer;
}
.instagramFrame{
    width: 100%;
    position: relative;
}
.point {
    background-color: #fff;
    font-size: 11px;
    color: #ff0069;
    border-radius: 4px;
    font-weight: 600;
    padding: 3px 6px;
}
.connectinstagrambutton3 {
    position: relative; /* 가상 요소 배치용 */
    align-self: stretch;
    border-radius: 8px;
    overflow: hidden; /* 가상 요소가 버튼을 넘어가지 않도록 */
    background: none; /* 직접 배경 제거 */
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 17px;
    cursor: pointer;
    color: white; /* 텍스트 색상 */
    z-index: 1; /* 텍스트가 배경 위로 오도록 */
}

.connectinstagrambutton3::before {
    content: '';
    position: absolute;
    width: 100dvw;
    height: 100dvw;
    background: linear-gradient(-45deg, #ffd600, #ff7a00, #ff0069, #d300c5, #7638fa);
    background-size: 300% 300%;
    animation: colorChange 1.5s linear infinite;
    z-index: -1; /* 배경을 버튼 뒤로 보냄 */
}
@keyframes colorChange {
    0% {
        background-position: 0% 50%;
        transform: rotate(0deg);
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
        transform: rotate(360deg);
    }
}
.connectinstagramframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    font-size: 15px;
}
.pick1 {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    flex-shrink: 0;
}
.pick2 {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    flex-shrink: 0;
}
.myhotplenum {
    position: relative;
    font-size: 20px;
    letter-spacing: -0.2px;
    line-height: 25px;
    font-weight: 600;
    color: #ff0069;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    flex-shrink: 0;
}
.myhotplenum2 {
    position: relative;
    font-size: 17px;
    letter-spacing: -0.2px;
    line-height: 25px;
    font-weight: 600;
    color: #7638fa;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    flex-shrink: 0;
}
.myhotpleinfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}
.verticalDivider{
    width: 0.7px;
    height: 50px ;
    background-color: #cdcdcd;
}
.hotpleinfos {
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #cdcdcd;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 6px 8px;
    gap: 10px;
    font-size: 13px;
}
.userheader {
    width: 100%;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 32px;
    box-sizing: border-box;
    gap: 24px;
    text-align: center;
    font-size: 16px;
    color: #1c1c1c;
    font-family: Pretendard;
}

