.b {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.headerFrame {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.nameText {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.nameTextDisabled {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
    color: #818181;
}
.titleFrame {
    align-self: stretch;
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 17px;
    color: #ff0069;
}
.deleteText {
    font-size: 13px;
    color: #818181;
    font-weight: 600;
    cursor: pointer;
}
.div {
    width: 42px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.nameInput1 {
    width: 100%;
    border: transparent;
    background-color: transparent;
    padding-right: 8px;
    text-align: left;
    color: #1c1c1c;
    font-family: Pretendard;
    font-size: 15px;
}
.nameInput1:focus {
    outline: none;
}
.nameInput1::placeholder {
    color: #CDCDCD;
}
.nameinput2 {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px 10px 12px;
    box-sizing: border-box;
    gap: 4px;
    text-align: left;
    cursor: pointer;
}
.buttonImageFrame{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.buttonImage{
    height: 32px;
}
.nameinput {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px 10px 12px;
    box-sizing: border-box;
    gap: 4px;
    text-align: left;
}
.nameFrame {
    align-self: stretch;
    width: 100%;
    display: flex;
    gap: 6px;
}
.periodFrame {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: right;
    font-size: 15px;
    color: #1c1c1c;
}
.div1 {
    width: 36px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 30px;
    flex-shrink: 0;
}
.periodInputFrame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-align: center;
    font-size: 13px;
}
.div8 {
    position: relative;
    font-size: 15px;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    color: #686868;
}
.periodInput1 {
    border-radius: 8px;
    width: 122px;
    background-color: #f2f2f2;
    border: transparent;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 6px 8px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: Pretendard;
}
.periodInput1:focus {
    outline: none;
}
.periodInput1::placeholder {
    color: #CDCDCD;
}
.groupFrame {
    align-self: stretch;
    border-bottom: 0.5px solid #cdcdcd;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 0px;
    gap: 12px;
}
.innerContentFrame {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: right;
    font-size: 15px;
}
.contentFrame {
    width: 366px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 56px;
    gap: 24px;
    max-height: calc(100dvh - 200px);
    min-height: 60px;
    overflow-y: scroll;
    margin-bottom: 47px;
}
.contentFrame::-webkit-scrollbar {
    width: 6px;
}
.contentFrame::-webkit-scrollbar-thumb {
    background-color: #ff6686;
    border-radius: 8px;
}
.contentFrame::-webkit-scrollbar-track {
    background-color: #e6e6e6;
}
.contentFrame::-webkit-scrollbar-button {
    display: none;
}
.backButton {
    flex: 1;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.addButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}
.addButtonDisabled {
    flex: 1;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #686868;
    cursor: not-allowed;
}
.editButton {
    width: 100%;
    border-radius: 8px;
    background-color: #ff0069;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}
.editButtonDisabled {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #686868;
    cursor: not-allowed;
}
.editPeriodCategoryModal {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 24px;
    text-align: center;
    font-size: 22px;
    color: #1c1c1c;
    font-family: Pretendard;
}
.buttonFrame {
    position: absolute;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding: 15px 50px;
    box-sizing: border-box;
    font-size: 17px;
    color: #ff0069;
}
.searchResults {
    position: relative;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 10;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}
.searchResults::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 두께 */
}

.searchResults::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.searchResults::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.searchResults::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}

.searchResultItem {
    padding: 6px;
    text-align: center;
    cursor: pointer;
}

.searchResultItem:hover {
    background-color: #f0f0f0;
}

.selectedHotpleList {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.iconpen{
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(4638%) hue-rotate(347deg) brightness(87%) contrast(78%);
}
.selectedHotple {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background-color: #ffefea;
    color: #1c1c1c;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
}
.selectedHotpleNum {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background-color: #f8effe;
    color: #421cb3;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
}

.removeButton {
    margin-left: 4px;
    width: 14px;
    height: 14px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

@media (max-width: 510px) {
    .contentFrame {
        width: calc(100% - 32px);
        padding: 32px 16px;
    }
}
