.span {
    color: #1c1c1c;
}
.p {
    margin: 0;
}
.span1 {
    color: #ff0069;
}
.p1 {
    margin: 0;
    color: #1c1c1c;
}
.title {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 16px;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.cancleButton {
    flex: 1;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.deleteButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
}
.buttons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 17px;
}
.contents {
    height: max-content;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
}
.header {
    width: 100%;
    overflow-y: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 0px 8px;
    box-sizing: border-box;
}
.header::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 두께 */
}
.header::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.header::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.header::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.container {
    width: calc(100dvw - 24px);
    height: calc(100dvh - 24px);
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    box-sizing: border-box;
    text-align: center;
    font-size: 22px;
    font-family: Pretendard;
}
.dataCount{
    font-size: 14px;
    margin-bottom: 8px;
    text-align: left;
    font-weight: 600;
    color: #ff0069;
}
.statisticTable {
    width: fit-content;
    font-size: 13px;
    margin-top: 20px;
    text-align: center; /* 텍스트를 테이블 내에서 중앙 정렬 */
    border-collapse: collapse; /* 테이블 셀 간의 경계선 제거 */
}
.statisticTable thead {
    background-color: #f2f2f2; /* 셀에 경계선 추가 */
    font-weight: 700;
}
.statisticTable tr {
    display: flex;
}
.statisticTable td {
    flex : 1;
    padding: 4px 8px; /* 셀 내부 간격 */
    border: 1px solid #ddd; /* 셀에 경계선 추가 */
}
.statisticTable tbody {
    font-weight: 400;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.confirmButton {
    align-self: stretch;
    border-radius: 8px;
    background-color: #ff0069;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.buttonFrame {
    position: absolute;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 50px;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
}

.tableFrame{
    width: 100%;
    height: max-content;
    overflow-x: auto;
    overflow-y: hidden;
}
.tableFrame::-webkit-scrollbar {
    height: 12px; /* 스크롤바의 두께 */
}
.tableFrame::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.tableFrame::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.tableFrame::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}

/* 테이블 스타일 */
.userTableFrame{
    height: max-content;
    padding: 0px 16px 24px;
}
.userTable {
    border-collapse: collapse;
}
.userTable th, .userTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}
input{
    width: calc(100% - 16px);
    border-radius: 4px;
    border: 0.5px solid #e0e0e0;
    font-size: 12px;
    font-family: Pretendard;
}
.id{
    min-width: 50px;
}
.date {
    min-width: 70px;
    width: 70px;
    user-select: text;
}
.count{
    min-width: 50px;
}
.ids{
    min-width: 120px;
    user-select: text;
}
.hotples{
    min-width: 100px;
}
input:focus{
    outline: none;
}
.instagramUsername{
    text-decoration: none;
    color: #ff0069;
    font-weight: 600;
	cursor: pointer;
}
.userTable th {
    background-color: #eeeeee;
    color: #333;
    font-weight: bold;
}
.userTable tr:nth-child(even) {
    background-color: #fbfbfb;
}
.userTable tr:hover {
    background-color: #f1f1f1;
}
.userTable thead th:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}
/* 테이블 데이터 크기와 텍스트 스타일 */
.userTable td {
    font-size: 12px;
}
.userTable th {
    font-size: 14px;
}

.filterInputWrapper {
    position: relative;
    display: inline-block;
}

.clearButton {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    cursor: pointer;
}

@media (max-width: 510px){
    .userTable {
        width: 100%;
    }
    .statisticTable {
        width: 100%;
    }
}