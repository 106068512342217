.span {
    color: #1c1c1c;
}
.p {
    margin: 0;
}
.span1 {
    color: #ff0069;
}
.p1 {
    margin: 0;
    color: #1c1c1c;
}
.title {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
* {
    outline: none;
}
.cancleButton {
    flex:1;
    height: 100%;
    font-size: 15px;
    padding-right: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.deleteButton {
    flex: 1;
    height: 100%;
    padding-left: 24px;
    display: flex;
    font-size: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
}
.contents {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.header {
    width: 100dvw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.usageSlider {
    width: 100%;
    align-self: center;
}
.contentImage {
    height: calc(100dvh - 200px);
    object-fit: contain;
}
.indicators {
    display: flex;
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
}
.indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #cdcdcd;
    margin: 0 5px;
    transition: width 0.2s ease, height 0.2s ease;
}
.activeIndicator {
    width: 12px;
    height: 12px;
    background-color: #fff;
}
.skipLayout {
    width: 100dvw;
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    margin-bottom: 20px;
    padding-right: 60px;
}
.skipText {
    width: 24px;
    height: 24px;
    font-size: 18px;
    font-weight: 500;
    padding: 8px;
    cursor: pointer;
}
.positiveButton {
    width: calc(100dvw - 64px);
    border-radius: 8px;
    background-color: #fff;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    font-weight: 600;
    color: #ff0069;
    cursor: pointer;
}
.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background: linear-gradient(31.35deg, #ff9000, #d900ff);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    color: #fff;
    font-family: Pretendard;
    z-index: 1000;
}