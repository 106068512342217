.b {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.headerframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.span {
    color: #ff0069;
}
.div {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 36px;
    font-size: 17px;
    font-weight: 600;
}
.li {
    margin-bottom: 0px;
}
.idHotplePick {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    padding-left: 24px;
}
.idHotplePickContainer {
    align-self: stretch;
    position: relative;
    font-size: 15px;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 17px;
}
.p {
    margin: 0;
}
.div1 {
    align-self: stretch;
    position: relative;
    font-size: 18px;
    letter-spacing: -0.2px;
    line-height: 26px;
    font-weight: 600;
}
.headerframeParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.cancleButton {
    flex: 1;
    border-radius: 8px;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 19px 16px;
    cursor: pointer;
}
.deleteButton {
    border-radius: 8px;
    background-color: #ff0069;
    font-size: 16px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 19px 16px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
}
.buttons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 17px;
    color: #ff0069;
}
.loginframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 0px 0px;
    gap: 44px;
}
.reviewmodal2 {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 40px;
    box-sizing: border-box;
    text-align: center;
    font-size: 22px;
    color: #1c1c1c;
    font-family: Pretendard;
}



@media (max-width: 510px){
    .reviewmodal2 {
        padding: 32px 28px;
    }
}