.reviewmodal4 {
  width: calc(100dvw - 24px);
  height: calc(100dvh - 24px);
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  box-sizing: border-box;
  text-align: center;
  font-size: 22px;
  font-family: Pretendard;
}
.mainframe1 {
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 13px;
  color: #421cb3;
}
.mainframe1::-webkit-scrollbar {
  width: 6px; /* 스크롤바의 두께 */
}
.mainframe1::-webkit-scrollbar-thumb {
  background-color: #ff6686; /* 스크롤바의 색상 */
  border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}
.mainframe1::-webkit-scrollbar-track {
  background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.mainframe1::-webkit-scrollbar-button {
  display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.mainframe {
  align-self: stretch;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 16px 0px;
  gap: 24px;
}
.backButton {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #ff0069;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.nametext {
  position: relative;
  letter-spacing: -0.3px;
  line-height: 22px;
  font-weight: 600;
}
.addButton {
  flex: 1;
  border-radius: 8px;
  background-color: #ff0069;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
.addButtonDisabled {
  flex: 1;
  border-radius: 8px;
  background-color: #f2f2f2;
    border: 1px solid #686868;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #686868;
  cursor:not-allowed;
}
.mainframe::-webkit-scrollbar {
  width: 6px; /* 스크롤바의 두께 */
}
.mainframe::-webkit-scrollbar-thumb {
  background-color: #ff6686; /* 스크롤바의 색상 */
  border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.mainframe::-webkit-scrollbar-track {
  background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.mainframe::-webkit-scrollbar-button {
  display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.headerframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.text {
  position: relative;
  letter-spacing: -0.3px;
  line-height: 22px;
  font-weight: 600;
}
.confirmButton {
  align-self: stretch;
  border-radius: 8px;
  background-color: #ff0069;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.buttonFrame {
  position: absolute;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 17px;
  color: #ff0069;
}
.b {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.instagrambutton {
    border-radius: 100px;
    background-color: #ffefea;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 20px;
    color: #ff0069;
    text-decoration: none;
}

.container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.errorBlock {
  margin-bottom: 40px;
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #eee;
}

.infoSection {
  margin-bottom: 20px;
}

.infoItem {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.infoItem strong {
  margin-right: 10px;
}

/* Responses section */
.responsesSection {
  margin-bottom: 20px;
}

.responseItem {
  margin-bottom: 15px;
}

.responseHeader {
  padding: 10px;
  background-color: #ececec;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.responseBody {
  padding: 0px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-top: 5px;
  max-width: 100%; /* 부모 요소의 너비를 기준으로 */
  display: block;
  white-space: nowrap; /* 한 줄로 표시 */
  overflow: hidden; /* 넘치는 텍스트는 숨김 */
  text-overflow: ellipsis; /* 넘치는 부분을 ...으로 표시 */
  cursor: pointer; /* 클릭 가능하게 포인터 변경 */
}

/* Possible URLs table */
.possibleUrlsSection {
  margin-top: 20px;
}

.urlTable {
  width: 100%;
  border-collapse: collapse;
}

.urlTable th,
.urlTable td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  cursor: pointer;
}

.urlTable th {
  background-color: #f1f1f1;
}

/* General clickable text */
.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: #f0f0f0;
}  