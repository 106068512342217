.b {
    font-size: 28px;
    font-family: Pretendard;
    color: #1c1c1c;
}
.mainheaderTxt {
    width: 100%;
}
.mainheader {
    width: 100%;
    position: relative;
    line-height: 34px;
    display: flex;
    align-items: center;
    font-family: Baloo Thambi;
}
.div {
    align-self: stretch;
    position: relative;
    font-size: 15px;
    letter-spacing: -0.08px;
    line-height: 20px;
    font-weight: 600;
    color: #353535;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    flex-shrink: 0;
}
.div2 {
    width: 30px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.xp {
    width: 80px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.div1 {
    align-self: stretch;
    border-radius: 8px;
    background-color: #f8effe;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 8px;
    gap: 16px;
}
.p {
    position: relative;
    letter-spacing: 0.06px;
    line-height: 13px;
    font-weight: 600;
}
.tagpoint {
    width: 30px;
    border-radius: 4px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 6px;
    box-sizing: border-box;
    font-size: 11px;
}
.km {
    flex: 1;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.xp1 {
    width: 80px;
    position: relative;
    letter-spacing: -0.08px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.div5 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 16px;
}
.div9 {
    position: relative;
    align-self: stretch;
    border-radius: 8px;
    background-color: #ffefea;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 16px;
    color: #ff0069;
}
.group {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    color: #9a9a9a;
}
.parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 13px;
    color: #421cb3;
}
.mainheaderParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}
.hedaerChild {
    align-self: stretch;
    position: relative;
    border-top: 1px solid #cdcdcd;
    box-sizing: border-box;
    height: 1px;
}
.span {
    color: #1c1c1c;
}
.mainheader1 {
    width: 100%;
    position: relative;
    line-height: 34px;
    display: flex;
    align-items: center;
}
.numbering {
    width: 20px;
    border-radius: 10px;
    background-color: #ff0069;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ai {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.tagpoint5 {
    border-radius: 4px;
    background-color: #ffefea;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 6px;
    box-sizing: border-box;
    font-size: 11px;
    color: #ff0069;
}
.aiParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.xp6 {
    color: #ff0069;
}
.xpContainer {
    position: relative;
    line-height: 16px;
}
.xpParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    text-align: left;
    font-size: 12px;
    color: #686868;
}
.frameParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    font-size: 17px;
    color: #1c1c1c;
}
.div16 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}
.text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 17px;
    color: #1c1c1c;
}
.container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    font-size: 15px;
    color: #fff;
}
.mainheaderGroup {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 12px;
    justify-content: flex-start;
    gap: 28px;
    font-size: 28px;
}
.hedaer {
    width: 366px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 8px 8px;
    box-sizing: border-box;
    gap: 32px;
}
.logoutmodal {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px 40px;
    box-sizing: border-box;
    text-align: center;
    font-size: 34px;
    color: #ff0069;
    font-family: Pretendard;
}

@media (max-width: 510px){
    .hedaer {
        width: 100%;
    }
}