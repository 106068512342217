.span {
    color: #ff0069;
}
.mainheader {
    align-self: stretch;
    position: relative;
    line-height: 34px;
    color: #1c1c1c;
}
.p {
    margin: 0;
    color: #1c1c1c;
}
.span1 {
    color: #1c1c1c;
}
.p1 {
    margin: 0;
    color: #ff0069;
}
.subheader {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.2px;
    line-height: 25px;
    font-weight: 600;
}
.hedaer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.cancleButton {
    flex: 1;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.logoutButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
}
.buttons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 17px;
    color: #ff0069;
}
.contents {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
}
.container2{
    width: 366px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 24px 8px;
    box-sizing: border-box;
}
.container {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px 40px;
    box-sizing: border-box;
    text-align: center;
    font-size: 22px;
    color: #ff0069;
    font-family: Pretendard;
}