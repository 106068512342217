.div1 {
    flex: 1;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.div {
    flex: 1;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 10px;
    box-sizing: border-box;
}
.iconsearch {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    filter: brightness(0) saturate(100%) invert(0%) sepia(86%) saturate(45%) hue-rotate(104deg) brightness(77%) contrast(78%);
}
.div2 {
    width: 44px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.searchframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 6px;
    gap: 4px;
}
.searchheaderChild {
    width: 100%;
    position: relative;
    background-color: #f2f2f2;
    height: 3px;
    min-height: 3px;
}
.text {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 600;
}
.regionButton {
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    box-sizing: border-box;
    cursor: pointer;
}
.regionButtonSelected {
    border-radius: 8px;
    background-color: #ff0069;
    color: #ffffff;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    box-sizing: border-box;
    cursor: pointer;
}
.regionbuttons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 16px;
    gap: 8px;
}
.placename {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
}
.placeaddress {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    color: #686868;
}
.leftinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
}
.category {
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    color: #686868;
}
.searchresult {
    align-self: stretch;
    border-bottom: 0.5px solid #cdcdcd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    text-align: left;
    font-size: 15px;
    color: #353535;
    cursor: pointer;
}
.bottomframe {
    align-self: stretch;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    font-size: 13px;
}
.bottomframe::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 두께 */
}
.bottomframe::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.bottomframe::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.bottomframe::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.searchheader {
    width: calc(100% - 32px);
    max-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    border: 0.5px solid #e6e6e6;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 17px;
    color: #1c1c1c;
    font-family: Pretendard;
    margin: 16px;
    z-index: 200;
}
.input {
    flex: 1;
    border-radius: 8px;
    border: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    text-align: left;
    color: #1c1c1c;
    font-family: Pretendard;
    font-weight: 600;
    font-size: 16px;
}
.input:focus {
    outline: none;
}
.input::placeholder{
    color: #686868;
}

@media (max-width: 918px){
    .searchframe{
        height: 48px;
    }
    .div{
        height: 48px;
    }
    .div2{
        height: 48px;
    }
}