.iconfire {
  width: 50px;
  position: relative;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
}
.hotple2Icon {
  width: 129px;
  position: relative;
  height: 26px;
  overflow: hidden;
  flex-shrink: 0;
}
.logo {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 36px 0px 16px;
}
.headertext_failed {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.24px;
  line-height: 20px;
  color: #ff0069;
}
.headertext {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.24px;
  line-height: 20px;
}
.id {
  width: 32px;
  position: relative;
  letter-spacing: -0.3px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.iconmarkerLineFilled {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.div {
  position: relative;
  letter-spacing: -0.24px;
  line-height: 20px;
}
.leftContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.iconcloseFilled {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.div1 {
  width: 44px;
  height: 44px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.idinput {
  flex: 1;
  border-radius: 8px;
  border: transparent;
  background-color: #f2f2f2;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px 10px 12px;
  box-sizing: border-box;
  gap: 47px;
  text-align: left;
  color: #1c1c1c;
  font-size: 15px;
}
.idinput:focus{
  outline: 1px solid #686868;
}
.idinput::placeholder{
  color: #686868;
}
.idbox {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.logininputs {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
.text {
  position: relative;
  letter-spacing: -0.3px;
  line-height: 22px;
  font-weight: 600;
}
.loginbutton {
  align-self: stretch;
  width: 80px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #ff0069;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 19px 0px;
  text-align: center;
  font-size: 17px;
  color: #ff0069;
  cursor: pointer;
}
.loginbox {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  text-align: right;
}
.loginframe {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}
.hotple {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.3px;
  line-height: 20px;
  font-weight: 600;
}
.registerbutton {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #ff0069;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-size: 17px;
  color: #ff0069;
  cursor: pointer;
}
.registerframe {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.header {
  width: 414px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 24px;
  box-sizing: border-box;
  gap: 36px;
}
.loginmodal {
  width: 100%;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 32px 32px 40px;
}
.modalcontainer {
  position: absolute;
  width: 478px;
  height: 404px;
}
.modalbackground {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1c1c1c;
  z-index: 1000;
}

@media (max-width: 510px){
  .loginmodal {
    padding: 16px 0px;
  }

  .header {
    width: 100%;
    padding: 24px;
  }
}