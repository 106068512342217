.iconlikeFilled {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}
.span {
    color: #ff0069;
}
.b {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.iconlikeFilledParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 22px;
    color: #1c1c1c;
}
.span1 {
    color: #1c1c1c;
}
.pickContainer {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 25px;
    font-weight: 600;
}
.myhotpleheader {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px 16px 24px 16px;
}
.divider{
    width: 100%;
    height: 16px;
    background-color: #e6e6e6;
}
.contents {
    align-self: stretch;
    height: fit-content;
    padding: 0px 35px 16px 35px;
}
.myhotpleframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.taghotpleframe {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 16px;
}
.mainframe {
    position: relative;
    display: flex;
    max-height: calc(100dvh - 200px);
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 0px 0px 0px;
    margin-bottom: 80px;
    gap: 8px;
}
.mainframe::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 두께 */
}

.mainframe::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.mainframe::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.mainframe::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.confirmButton {
    align-self: stretch;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.buttonFrame {
    position: absolute;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 50px;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
}
.addedhotplemodal {
    width: 450px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    height: auto;
    overflow: hidden;
    text-align: center;
    font-size: 20px;
    color: #ff0069;
    font-family: Pretendard;
}
.username{
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

@media (max-width: 510px){
    .addedhotplemodal {
        width: 100%;
    }
    .contents {
        padding: 0px 16px 16px 16px;
    }
    .buttonFrame {
        padding: 15px;
    }
}