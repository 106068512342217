.map_copyright {
  display: none !important;
}

.map{
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
}

.p_marker {
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  color: #1c1c1c;
}
.p1_marker {
  margin: 0;
  font-size: 11px;
  line-height: 11px;
  color: #ff0069;
}
.hotBadge {
  position: absolute;
  top: 0px; /* 컴포넌트의 상단을 넘어 튀어나오게 설정 */
  right: -6px; /* 컴포넌트의 우측을 넘어 튀어나오게 설정 */
  background: linear-gradient(45deg, #ff0069, #FF7A00); /* 눈에 띄는 배경색 */
  color: white;
  font-size: 7px;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 20px;
  filter: drop-shadow(0 0 4px rgba(255, 102, 0, 0.6));
  z-index: 10; /* 배지가 다른 요소 위로 오도록 설정 */
  will-change: transform;
}
.newBadge {
  position: absolute;
  top: 0px; /* 컴포넌트의 상단을 넘어 튀어나오게 설정 */
  right: -6px; /* 컴포넌트의 우측을 넘어 튀어나오게 설정 */
  background: linear-gradient(45deg, #FF7A00, #ffd600); /* 눈에 띄는 배경색 */
  color: white;
  font-size: 7px;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 20px;
  filter: drop-shadow(0 0 4px rgba(255, 196, 0, 0.6));
  z-index: 10; /* 배지가 다른 요소 위로 오도록 설정 */
  will-change: transform;
}
.periodBadge {
  position: absolute;
  top: 0px; /* 컴포넌트의 상단을 넘어 튀어나오게 설정 */
  right: -10px; /* 컴포넌트의 우측을 넘어 튀어나오게 설정 */
  background: linear-gradient(45deg, #7638FA, #D300C5); /* 눈에 띄는 배경색 */
  color: white;
  font-size: 8px;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 20px;
  filter: drop-shadow(0 0 4px rgba(204, 0, 255, 0.4));
  z-index: 10; /* 배지가 다른 요소 위로 오도록 설정 */
  will-change: transform;
}
.endBadge {
  position: absolute;
  top: 0px; /* 컴포넌트의 상단을 넘어 튀어나오게 설정 */
  right: -10px; /* 컴포넌트의 우측을 넘어 튀어나오게 설정 */
  background: linear-gradient(45deg, #818181, #b3b3b3); /* 눈에 띄는 배경색 */
  color: white;
  font-size: 8px;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 20px;
  filter: drop-shadow(0 0 4px rgba(153, 153, 153, 0.4));
  z-index: 10; /* 배지가 다른 요소 위로 오도록 설정 */
  will-change: transform;
}
.p2_marker {
  margin: 0;
  font-size: 10px;
  line-height: 14px;
  color: #421cb3;
}
.div_marker {
  flex: 1;
  position: relative;
  font-weight: 700;
  text-shadow: 1px 0 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
}
.markercourseAddIcon {
  margin-top: 2px;
  width: 35px;
  position: relative;
  height: 40px;
}
.parent_marker {
  width: max-content;
  height: 49px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-family: Pretendard;
}
.iconcloseLine {
	position: relative;
	height: 100%;
	overflow: hidden;
	filter: brightness(0) saturate(100%) invert(0%) sepia(86%) saturate(45%) hue-rotate(104deg) brightness(77%) contrast(78%);
	cursor: pointer;
}
.iconcloseLineParent {
	width: 100%;
	position: relative;
	height: 28px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
  margin-top: 4px;
  margin-left: -4px;
	padding: 4px;
	box-sizing: border-box;
}

.cluster {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 2px solid white;
  font-family: 'Pretendard';
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
}

.cluster-1 {
  background-color: #FF0069;
  width: 40px;
  height: 40px;
  font-size: 14px;
}

.cluster-2 {
  background-color: #FF0069;
  width: 50px;
  height: 50px;
  font-size: 16px;
}

.cluster-3 {
  background-color: #FF0069;
  width: 60px;
  height: 60px;
  font-size: 18px;
}

.iconrefresh_searchBT {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.label {
  position: relative;
  letter-spacing: -0.24px;
  line-height: 20px;
}
.buttonMap_searchBT {
  position: absolute;
  bottom: 50px;
  z-index: 100;
  width: 160px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  background-color: #fff;
  border: 1px solid #ff0069;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  gap: 4px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #ff0069;
  font-family: Pretendard;
  cursor: pointer;
}
.iconinstagram {
  width: 18px;
  position: relative;
  height: 18px;
}
.iconcourse {
  width: 20px;
  position: relative;
  height: 20px;
}
.iconshare {
  width: 18px;
  position: relative;
  height: 18px;
}
.instagramtext {
  position: relative;
  letter-spacing: 0.06px;
  line-height: 13px;
  font-weight: 600;
}
.instagrambutton {
  width: 90px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  text-decoration: none;
	color: inherit;
}
.sharebutton {
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  text-decoration: none;
	color: #1c1c1c;
}
.divider {
  width: 1px;
  position: relative;
  border-right: 1px solid #cdcdcd;
  box-sizing: border-box;
  height: 33px;
}
.routebutton {
  width: 90px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #7638fa;
  cursor: pointer;
  text-decoration: none;
}
.infoframe {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: 13px;
  color: #ff0069;
  font-family: Pretendard;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}
.iconmyLocation {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  filter: brightness(0) saturate(100%) invert(19%) sepia(71%) saturate(4262%) hue-rotate(252deg) brightness(75%) contrast(118%);
}
.locationMapControl {
  position: absolute;
  width: 44px;
  bottom: 32px;
  left: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: #fff;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
}
.iconplusLine {
  filter: brightness(0) saturate(100%) invert(19%) sepia(71%) saturate(4262%) hue-rotate(252deg) brightness(75%) contrast(118%);
}
.iconminusLine {
  filter: brightness(0) saturate(100%) invert(19%) sepia(71%) saturate(4262%) hue-rotate(252deg) brightness(75%) contrast(118%);
}
.plusButton {
  align-self: stretch;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.minusButton {
  align-self: stretch;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.zoombuttondivider {
  align-self: stretch;
  position: relative;
  border-top: 1px solid #e6e6e6;
  box-sizing: border-box;
  height: 1px;
}
.zoomMapControl {
  width: 44px;
  height: 88px;
  position: absolute;
  bottom: 32px;
  right: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

@media (max-width: 918px){
  .buttonMap_searchBT {
    bottom: 116px;
  }
  .zoomMapControl {
    right: 16px;
    bottom: 116px;
  }
  .locationMapControl {
    left: 16px;
    bottom: 116px;
  }
}