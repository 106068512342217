.browserContainer{
    display: flex;
    width: 100%;
    height: 100%;
}

.mobileContainer{
    display: flex;
    width: 100%;
    height: 100%;
}

.mainFrameContainer{
    position:relative;
    width: 100dvw;
    height: 100dvh;
}