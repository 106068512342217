@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi:wght@400&display=swap');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.css");

body {
  margin: 0;  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}
* {
  user-select: none;
}

.buttonBadge {
  position: absolute;
  top: -10px; /* 컴포넌트의 상단을 넘어 튀어나오게 설정 */
  right: -6px; /* 컴포넌트의 우측을 넘어 튀어나오게 설정 */
  background: linear-gradient(45deg, #ff0069, #ffd600); /* 눈에 띄는 배경색 */
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(255, 122, 0, 0.7);
  animation: pulse 1.5s infinite; /* 애니메이션 추가 */
  z-index: 10; /* 배지가 다른 요소 위로 오도록 설정 */
}
@keyframes pulse {
  0%, 100% {
      transform: scale(1.0);
      opacity: 1;
  }
  50% {
      transform: scale(1.2);
      opacity: 0.8;
  }
}