.div {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 25px;
    font-weight: 600;
}
.headerframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.div1 {
    flex: 1;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
}
.iconcheckedFilled {
    filter: brightness(0) saturate(100%) invert(16%) sepia(100%) saturate(6495%) hue-rotate(329deg) brightness(99%) contrast(106%);
    width: 21px;
    position: relative;
    height: 21px;
}
.iconchecked {
    filter: brightness(0) saturate(100%) invert(65%) sepia(6%) saturate(0%) hue-rotate(196deg) brightness(95%) contrast(82%);
    width: 21px;
    position: relative;
    height: 21px;
}
.div2 {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
}
.div2Disabled {
    color: #9a9a9a;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
}
.iconcheckedFilledParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-align: right;
    color: #ff0069;
}
.captionframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 12px;
    gap: 12px;
    cursor: pointer;
}
.iconcheckedFilledGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-align: right;
    color: #818181;
}
.captionframe1 {
    align-self: stretch;
    border-top: 0.5px solid #9a9a9a;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 12px;
    gap: 12px;
    cursor: pointer;
}
.contentsframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 16px;
}
.headerframeParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.loginframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 0px 8px;
}
.reviewmodal2 {
    width: 478px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 40px;
    box-sizing: border-box;
    text-align: center;
    font-size: 20px;
    color: #1c1c1c;
    font-family: Pretendard;
}

@media (max-width: 510px){
    .reviewmodal2 {
        width: 100%;
    }
}