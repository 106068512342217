.div {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 25px;
    font-weight: 600;
    cursor: pointer;
}
.iconsearch {
    width: 23px;
    position: relative;
    height: 23px;
    overflow: hidden;
    flex-shrink: 0;
    filter: brightness(0) saturate(100%) invert(0%) sepia(6%) saturate(2745%) hue-rotate(21deg) brightness(169%) contrast(78%);
}
.div1 {
    flex: 1;
    position: relative;
    font-size: 17px;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
    color: #ff0069;
    text-align: right;
}
.parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}
.frameParent {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #cdcdcd;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 16px;
    text-align: left;
    font-size: 19px;
    color: #1c1c1c;
    font-family: Pretendard;
    cursor: pointer;
}

