.icon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    filter: brightness(0) saturate(100%) invert(38%) sepia(4%) saturate(12%) hue-rotate(10deg) brightness(106%) contrast(93%);
}
.frame {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.div1 {
    position: relative;
    letter-spacing: 0.06px;
    line-height: 13px;
    font-weight: 600;
    margin-top: -2px;
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px 0px 7px;
}
.div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #686868;
}
.div2 {
    flex: 1;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #686868;
}
.active {
    background-color: #ff0069;
    color: #fff;
}
.active .icon {
    filter: invert(0%);
}
.tabBarglobal {
    width: 100%;
    position: fixed;
    bottom: 0;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-top: 0.5px solid #cdcdcd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 11px;
    color: #fff;
    font-family: Pretendard;
    z-index: 500;
}

