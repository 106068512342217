.div1 {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.iconchevronBottom {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
}
.km {
    position: relative;
    font-size: 15px;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    color: #ff0069;
}
.div {
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.iconsearch {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(4638%) hue-rotate(347deg) brightness(87%) contrast(78%);
}
.hotpleGroup{
    height: 36px;
    cursor: pointer;
}
.div2 {
    width: 44px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.locationSearchBar {
    align-self: stretch;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    border: 0.5px solid #e6e6e6;
    box-sizing: border-box;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 16px 16px 0px;
    padding: 0px 6px 0px 16px;
    cursor: pointer;
}
.iconstarFilled {
    width: 18px;
    position: relative;
    height: 18px;
}
.label {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
}
.buttonplaceCategorySelected {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    width: max-content;
    border-radius: 100px;
    background-color: #ffefea;
    border: 0.5px solid #ff0069;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    color: #ff0069;
    cursor: pointer;
}
.buttonplaceCategory {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    width: max-content;
    border-radius: 100px;
    background-color: #fff;
    border: 0.2px solid #e6e6e6;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    cursor: pointer;
}
.buttonplaceCategoryPeriod {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    width: max-content;
    border-radius: 100px;
    background-color: #686868;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    color: #fff;
    cursor: pointer;
}
.list {
    display: flex;
    flex-direction: row;
    gap: 6px;
}
.div3 {
    height: 44px;
    padding: 0px 16px 0px 16px;
    display: flex;
    align-items: center;
    overflow-x: auto; /* 가로 스크롤 활성화 */
    -webkit-overflow-scrolling: touch;
}
.div3::-webkit-scrollbar {
    display: none; /* 웹킷 브라우저에서 스크롤바 숨기기 */
}
.categorybuttons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: 14px;
}
.mapHeader {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 2px;
    text-align: left;
    font-size: 17px;
    color: #1c1c1c;
    font-family: Pretendard;
    z-index: 100;
}

@media (max-width: 918px){
    .locationSearchBar{
        height: 48px;
    }
}