.title {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
    color: #1c1c1c;
}
.hotple {
    line-height: 26px;
    font-size: 19px;
}
.span {
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
    font-family: Pretendard;
    color: #1c1c1c;
}
.hotpleDm {
    margin: 0;
}
.a1 {
    font-size: 19px;
    line-height: 26px;
    font-family: Pretendard;
    color: #ff0069;
}
.span1 {
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
}
.a {
    margin: 0;
    font-size: 20px;
    color: #1c1c1c;
}
.description1 {
    width: 100%;
    max-width: 500px;
    position: relative;
    letter-spacing: -0.2px;
}
.description {
    align-self: stretch;
    position: relative;
    border-radius: 12px;
    letter-spacing: -0.2px;
}
.text {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.codeText {
    position: relative;
    width: 100%;
    line-height: 34px;
    font-weight: 700;
    font-size: 32px;
    user-select: text;
}
.text1 {
    position: relative;
    font-size: 17px;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.dmButton {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 19px 49px;
    gap: 0px;
    font-size: 17px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: inherit;
}
.innerContents{
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.codeFrame {
    align-self: stretch;
    border-radius: 8px;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-size: 17px;
    text-align: center;
}
.contents {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}
.id3 {
    font-weight: 600;
    font-family: Pretendard;
}
.id2 {
    color: #ff0069;
}
.li {
    margin-bottom: 0px;
}
.id {
    margin: 0;
    font-size: inherit;
    padding-left: 17px;
}
.detail {
    align-self: stretch;
    position: relative;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
    text-align: left;
    color: #1c1c1c;
}
.pick {
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    color: #1C1C1C;
}
.pickContainer {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.24px;
    line-height: 24px;
}
.iconinstagramColor {
    width: 24px;
    position: relative;
    height: 24px;
}
.buttondefault {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-size: 17px;
    cursor: pointer;
}
.iconfire1 {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}
.connectinstagrambutton2 {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 17px;
    color: #ff0069;
    cursor: pointer;
}
.header {
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 24px 8px;
    gap: 36px;
}
.connectButtonFrame{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.container {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    text-align: center;
    font-size: 22px;
    color: #ff0069;
    font-family: Pretendard;
}
.positiveButton {
    flex: 1;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
}
.buttons {
    align-self: stretch;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 16px 16px 16px;
    gap: 16px;
}
.negativeButton {
    width: 120px;
    border-radius: 8px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    padding: 19px 16px;
    box-sizing: border-box;
    cursor: pointer;
}