.div {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-size: 16px;
    font-weight: 600;
}
.hotple1 {
    color: #ff0069;
    font-family: 'Baloo Thambi';
    font-weight: 500;
    letter-spacing: -1px;
    font-size: 28px;
}
.hotple {
    width: fit-content;
    position: relative;
    font-size: 25px;
    line-height: 34px;
    display: inline-block;
    color: #1c1c1c;
}
.bannerNotice{
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
}
.bannersFrame{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:12px;
}
.title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1px;
    padding-top: 8px;
}
.background {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    background: linear-gradient(117.15deg, #7638fa, #ff0069);
    width: 100%;
    height: 160px;
}
.background2 {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    background: linear-gradient(117.15deg, #7638fa, #ff0069);
    width: 100%;
    height: 76px;
}
.iconmyLocation {
    position: absolute;
    right: 10px;
    bottom: 6px;
    width: 70px;
    height: 70px;
    overflow: hidden;
}
.iconinstagram {
    position: absolute;
    right: 16px;
    bottom: 10px;
    width: 56px;
    height: 56px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7412%) hue-rotate(346deg) brightness(125%) contrast(119%);
    overflow: hidden;
}
.ai {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.p {
    margin: 0;
}
.div1 {
    position: relative;
    font-size: 12px;
    line-height: 16px;
}
.text {
    position: absolute;
    top: 16px;
    left: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
}
.text2 {
    position: absolute;
    top: 14px;
    left: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
}
.recommendbuttonChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 160px;
}
.recommendbuttonChild2 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 80px;
}
.iconlock {
    width: 45px;
    position: relative;
    height: 45px;
    opacity: 0.8;
}
.div2 {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
    display: inline-block;
    height: 18px;
    flex-shrink: 0;
}
.iconlockParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
}
.funcButtonParent{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: fit-content;
}
.iconlockParent2 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
    text-align: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
}
.recommendbutton {
    flex: 1;
    position: relative;
    height: 160px;
    cursor: pointer;
}
.recommendbutton2 {
    flex: 1;
    position: relative;
    height: 76px;
    cursor: pointer;
}
.background1 {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    background: linear-gradient(117.15deg, #ff0069, #ffd600);
    width: 100%;
    height: 160px;
}
.iconfire {
    position: absolute;
    right: 3px;
    bottom: 6px;
    width: 70px;
    height: 70px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7412%) hue-rotate(346deg) brightness(125%) contrast(119%);
    overflow: hidden;
}
.iconlockGroup {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
}
.funcButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    opacity: 0.85;
    font-size: 20px;
    color: #fff;
}
.iconstarFilled {
    width: 32px;
    position: relative;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
}
.hotple2 {
    position: relative;
    letter-spacing: -0.24px;
    line-height: 20px;
    font-size: 14px;
}
.hotple100 {
    position: relative;
    font-size: 17px;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.manualbutton {
    width: 100%;
    border-radius: 8px;
    background-color: #ffefea;
    border: 2px solid #ff0069;
    box-sizing: border-box;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 20px;
    gap: 16px;
    font-size: 15px;
    cursor: pointer;
}
.hotpleitems{
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.topcontents {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 16px 0px 16px;
    gap: 16px;
}
.title1 {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 16px 0px 16px;
}
.contents1 {
    height: 100%;
}
.bottomcontents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 22px;
    width: 100%;
    color: #1c1c1c;
}
.innercontents {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px;
    gap: 40px;
}
.scrollBar1 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    background-color: #ff6686;
    width: 6px;
    height: 200px;
}
.scrollBar {
    align-self: stretch;
    width: 6px;
    position: relative;
    background-color: #e6e6e6;
    overflow: hidden;
    flex-shrink: 0;
}
.maincontents {
    display: flex;
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.maincontents::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 두께 */
}

.maincontents::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.maincontents::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.maincontents::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.contents {
    position: relative;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    text-align: left;
    font-size: 17px;
    color: #ff0069;
    font-family: Pretendard;
}
.frame{
    height: 100%;
}
.divider {
    position: relative;
    background-color: #e6e6e6;
    height: 6px;
    width: 100%;
    min-height: 6px;
}
.markerstoryIcon {
    width: 69px;
    position: relative;
    opacity: 0.3;
    height: 80px;
}
.p9 {
    margin: 0;
}
.div10 {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
}
.div9 {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0px;
    box-sizing: border-box;
    gap: 8px;
    text-align: center;
    font-size: 16px;
    color: #B3B3B3;
    font-family: Pretendard;
}

@media (max-width: 918px){
    .contents{
        height: fit-content;
    }
    .maincontents{
        overflow-y: hidden;
    }
}