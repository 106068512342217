.iconfire {
    width: 44px;
    position: relative;
    height: 44px;
    overflow: hidden;
    flex-shrink: 0;
}
.logo {
    width: 64px;
    border-bottom: 1px solid #cdcdcd;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 10px;
}
.icon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    filter: brightness(0) saturate(100%) invert(38%) sepia(4%) saturate(12%) hue-rotate(10deg) brightness(106%) contrast(93%);
}
.div {
    width: 48px;
    position: relative;
    line-height: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
}
.div1 {
    width: 48px;
    position: relative;
    line-height: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
}
.button, .button1 {
    align-self: stretch;
    height: 64px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 10px 12px;
    box-sizing: border-box;
    gap: 6px;
    cursor: pointer;
}
.button {
    background-color: transparent;
    color: #686868;
}
.button1 {
    background-color: transparent;
    color: #686868;
}
.active {
    background-color: #ff0069;
    color: #fff;
}
.active .icon {
    filter: invert(0%);
}
.menu {
    width: 64px;
    height: 100%;
    position: relative;
    background-color: #fff;
    border-right: 1px solid #cdcdcd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    float:left;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: 12px;
    font-family: Pretendard;
}
