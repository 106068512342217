.b {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.2px;
    line-height: 28px;
}
.instagrambutton {
    border-radius: 100px;
    background-color: #ffefea;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 20px;
    color: #ff0069;
    text-decoration: none;
}
.headerframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.nametext {
    position: relative;
    letter-spacing: -0.3px;
    line-height: 22px;
    font-weight: 600;
}
.iconpen {
    width: 18px;
    position: relative;
    height: 18px;
    padding-bottom: 2px;
    overflow: hidden;
    flex-shrink: 0;
}
.nameframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    font-size: 17px;
    color: #1c1c1c;
    cursor: pointer;
}
.maincategorytext {
    position: relative;
    letter-spacing: 0.06px;
    line-height: 13px;
}
.tagplaceDetail {
    border-radius: 4px;
    background-color: #f8effe;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
}
.tagplaceDetail1 {
    border-radius: 4px;
    background-color: #fffaea;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
    color: #b74400;
}
.categoryframe {
    align-self: stretch;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
}
.tags1 {
    border-radius: 4px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
}
.tagsframe {
    align-self: stretch;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    text-align: center;
    font-size: 11px;
    color: #686868;
    cursor: pointer;
}
.captionFrame{
    display: flex;
    width: 366px;
    flex-direction: column;
    gap: 4px;
}
.mediaCaption{
    font-size: 11px;
    height: 100%;
    overflow-y: auto;
    padding: 8px;
    border-radius: 8px;
    background-color: #e6e6e6;
}
.mediaCaption1{
    font-size: 14px;
    font-weight: 600;
}
.captiontext {
    flex: 1;
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
    font-weight: 600;
}
.captionframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: #1c1c1c;
    cursor: pointer;
}
.label1 {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
}
.periodtext {
    position: relative;
    letter-spacing: -0.08px;
    line-height: 18px;
    color: #1c1c1c;
}
.periodframe {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;
}
.placeframe {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;
}
.mainframe1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 0px 16px;
    gap: 8px;
    text-align: left;
    font-size: 13px;
    color: #421cb3;
}
.mainframe {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px 0px;
    gap: 24px;
}
.finishButton {
    width: 100%;
    border-radius: 8px;
    background-color: #ff0069;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
}
.finishButtonDisabled {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    border: 1px solid #686868;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 17px;
    color: #686868;
    cursor: not-allowed;
}
.reviewmodal4 {
    width: 478px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 56px;
    box-sizing: border-box;
    gap: 24px;
    text-align: center;
    font-size: 22px;
    color: #1c1c1c;
    font-family: Pretendard;
    max-height: calc(100dvh - 24px);
    overflow-y: auto;
}
.reviewmodal4::-webkit-scrollbar {
    display: none;
}
.commentframe {
    align-self: stretch;
    border-top: 1px solid #e6e6e6;
    padding-top: 16px;
    margin-top: 16px;
    width: 100%;
    display: flex;
    gap: 12px;
}
.commentdiv {
    width: 42px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 13px;
    flex-shrink: 0;
    color: #1c1c1c;
}
.commentdivTaggedBy {
    width: 12px;
    position: relative;
    letter-spacing: -0.3px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: top;
    font-size: 13px;
    flex-shrink: 0;
    color: #1c1c1c;
}
.commentinput {
    width: 100%;
    border-radius: 8px;
    background-color: #f2f2f2;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px 10px 8px;
    box-sizing: border-box;
    gap: 4px;
    text-align: left;
}
.commentInput1 {
    width: 100%;
    border: transparent;
    background-color: transparent;
    padding-right: 8px;
    text-align: left;
    color: #1c1c1c;
    font-family: Pretendard;
    font-size: 13px;
}
.commentInput1:focus{
    outline: none;
}
.commentInput1::placeholder{
    color: #CDCDCD;
}
.label {
    position: relative;
    line-height: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #421cb3;
}
.commentUser {
    font-weight: 600;
  text-decoration: none;
  color: #ff0069;
}
.comment {
    flex: 1;
    position: relative;
    line-height: 16px;
    color: #ff0069;
}
.span {
    color: #1c1c1c;
}

@media (max-width: 510px){
    .reviewmodal4 {
        width: 100%;
        padding: 32px 24px;
    }
    .finishButton {
        width: 100%;
    }
}