.iconcloseLine {
align-self: stretch;
flex: 1;
position: relative;
max-width: 100%;
overflow: hidden;
max-height: 100%;
}
.closebutton {
position: absolute;
top: 16px;
right: 16px;
width: 40px;
height: 40px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
padding: 8px;
box-sizing: border-box;
cursor: pointer;
}
.backbutton {
position: absolute;
top: 16px;
left: 16px;
width: 40px;
height: 40px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
padding: 8px;
box-sizing: border-box;
cursor: pointer;
}
.modalcontainer {
position: absolute;
width: fit-content;
height: fit-content;
opacity: 0; /* 초기 투명도 */
transition: opacity 200ms ease-in-out;
}
.modalbackground {
width: 100%;
position: absolute;
top: 0;
left: 0;
background-color: rgba(0, 0, 0, 0.8);
height: 100%;
overflow: hidden;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
font-size: 15px;
color: #1c1c1c;
font-family: Pretendard;
z-index: 1000;
opacity: 0; /* 초기 상태 */
transition: opacity 200ms ease-in-out; /* 200ms 동안 변화 */
pointer-events: none; /* 비활성화 */
}
.modalbackground.visible {
    opacity: 1; /* 애니메이션 종료 후 상태 */
    pointer-events: auto; /* 클릭 가능 */
}
.modalcontainer.visible {
    opacity: 1; /* 불투명 */
}

@media (max-width: 510px){
    .modalcontainer {
        width: 100%; /* 부모 요소가 페이지 전체를 차지한다고 가정 */
        padding: 0 12px; /* 좌우 패딩을 각각 16px 설정 */
        box-sizing: border-box; /* 패딩을 포함한 너비를 계산 */
    }
    .closebutton {
        top: 12px;
        right: 24px;
    }
    .backbutton {
        top: 12px;
        left: 24px;
    }
}