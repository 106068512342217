.divider {
    position: relative;
    background-color: #e6e6e6;
    height: 6px;
    width: 100%;
    min-height: 6px;
}
.contents {
    position: relative;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    text-align: center;
    font-size: 40px;
    color: #ff0069;
    font-family: Pretendard;
}
.refreshButton{
    border-radius: 100px;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    background-color: #ff0069;
    color:#fff;
    cursor: pointer;
    font-weight: 600;
}
.hotpleitems{
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.frame{
    height: 100%;
}
.hotpleitems::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 두께 */
}

.hotpleitems::-webkit-scrollbar-thumb {
    background-color: #ff6686; /* 스크롤바의 색상 */
    border-radius: 8px; /* 스크롤바의 테두리 둥글게 */
}

.hotpleitems::-webkit-scrollbar-track {
    background-color: #e6e6e6; /* 스크롤바 트랙(배경)의 색상 */
}

.hotpleitems::-webkit-scrollbar-button {
    display: none; /* 스크롤바 상단, 하단 버튼 숨기기 */
}
.label {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
}
.allbutton {
    border-radius: 100px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    color:#1c1c1c;
    cursor: pointer;
}
.allbuttonSelected {
    border-radius: 100px;
    background-color: #f8effe;
    border: 1px solid #7638fa;
    color:#7638fa;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    cursor: pointer;
}
.iconupdate {
    width: 20px;
    position: relative;
    height: 20px;
}
.iconstar {
    width: 20px;
    position: relative;
    height: 20px;
    filter: brightness(0) saturate(100%) invert(56%) sepia(86%) saturate(3149%) hue-rotate(359deg) brightness(102%) contrast(107%);
}
.needverifybutton {
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    color: #ff0069;
    cursor: pointer;
}
.needverifybuttonSelected {
    border-radius: 100px;
    background-color:#ffefea;
    border: 1px solid #ff0069;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    color: #ff0069;
    cursor: pointer;
}
.mypickbutton {
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    color: #ff7a00;
    cursor: pointer;
}
.mypickbuttonSelected {
    border-radius: 100px;
    background-color: #fffaea;
    border: 1px solid #ff7a00;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    color: #ff7a00;
    cursor: pointer;
}
.periodbutton {
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    cursor: pointer;
}
.periodbuttonSelected {
    border-radius: 100px;
    background-color: #f8effe;
    border: 1px solid #7638fa;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    gap: 4px;
    cursor: pointer;
}
.label4 {
    position: relative;
    letter-spacing: -0.24px;
    line-height: 20px;
}
.usernamebutton {
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    font-size: 15px;
    color: #1c1c1c;
    cursor: pointer;
}
.usernamebuttonSelected {
    border-radius: 100px;
    background-color: #f8effe;
    border: 1px solid #7638fa;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    font-size: 15px;
    color: #7638fa;
    cursor: pointer;
}
.filterbuttons {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 10px 16px;
    box-sizing: border-box;
    gap: 6px;
    text-align: center;
    font-size: 15px;
    color: #7638fa;
    font-family: Pretendard;
}
.iconsearch {
    width: 18px;
    position: relative;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    filter: brightness(0) saturate(100%) invert(78%) sepia(1%) saturate(0%) hue-rotate(289deg) brightness(91%) contrast(93%);
}
.iconsearchActive {
    width: 18px;
    position: relative;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    filter: brightness(0) saturate(100%) invert(16%) sepia(91%) saturate(6981%) hue-rotate(329deg) brightness(100%) contrast(105%);
}
.iconsearch2 {
    width: 18px;
    position: relative;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
}
.label5 {
    flex: 1;
    position: relative;
    letter-spacing: -0.24px;
    line-height: 20px;
    color: #ff0069;
    caret-color: #1c1c1c;
    font-size: 14px;
    border: transparent;
}
.label5:focus {
    outline: none;
}
.label5::placeholder{
    color: #b3b3b3;
}
.leftContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 16px 0px 0px;
}
.div {
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.searchfield {
    width: calc(100% - 32px);
    margin: 6px 0px 4px 0px;
    padding-bottom: 2px;
    position: relative;
    border-bottom: 0.5px solid #cdcdcd;
    box-sizing: border-box;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    color: #b3b3b3;
    font-family: Pretendard;
}
.searchfieldActive {
    width: calc(100% - 32px);
    margin: 6px 0px 4px 0px;
    padding-bottom: 2px;
    position: relative;
    border-bottom: 1px solid #ff0069;
    box-sizing: border-box;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    color: #b3b3b3;
    font-family: Pretendard;
}
.markerstoryIcon {
    width: 69px;
    position: relative;
    opacity: 0.3;
    height: 80px;
}
.p9 {
    margin: 0;
}
.div10 {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    font-weight: 600;
}
.div9 {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 44px 0px;
    box-sizing: border-box;
    gap: 8px;
    text-align: center;
    font-size: 16px;
    color: #B3B3B3;
    font-family: Pretendard;
}

@media (max-width: 918px){
    .contents{
        height: fit-content;
    }
    .hotpleitems{
        overflow-y:hidden;
    }
}